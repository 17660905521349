import React from 'react';
import { FormattedMessage } from 'react-intl';
//import * as coolData from '../data/distributors-data.json';
import Page from '../templates/Page';
import ShopOnline from '../components/ShopOnline';
import GMaps from '../components/GMaps';

const Distributors = (props) => {
	//const distributorsArray = [ 'italy', 'france', 'canada', 'switzerland' ];
	/*
	SETTING OF GMAPS IN distributors-data.json
	OLD:
	// {
	// 	"type": "Feature",
	// 	"properties": {
	// 		"ID": 3,
	// 		"TITLE": "Canada Import",
	// 		"NAME": "FRED ALONZI WINES & SPIRITS INC",
	// 		"ADDRESS": "989 Creditstone Road ",
	// 		"ADDRESS1": "Concord Ontario L 4K 4N7 CANADA"
	// 	},
	// 	"geometry": {
	// 		"type": "Point",
	// 		"coordinates": [ -75.898610599532319, 45.295014379864874 ]
	// 	}
	// },
	*/
	const distributorsArray = [ 'italy', 'france', 'germany', 'switzerland', 'canada' ];
	const coolData = {};
	return (
		<Page {...props}>
			<GMaps vhHeight="70" vwWidth="100" data={coolData} />
			<section className="distributors-features">
				<div className="container-fluid">
					<div className="title">
						<h1 className="text-center">
							<FormattedMessage id={`distributors.title`} />
						</h1>
					</div>
				</div>

				<div className="container-grid">
					<div className="grid-row">
						{distributorsArray.map((zone) => (
							<div key={zone} className="grid-item">
								<h3>
									<FormattedMessage id={`distributors.${zone}.qualify`} />
								</h3>
								<p>
									<b>
										<FormattedMessage id={`distributors.${zone}.title`} />
									</b>
									<br />
									<FormattedMessage id={`distributors.${zone}.address-title`} />
									<FormattedMessage id={`distributors.${zone}.address`} />
									<br />
									<FormattedMessage id={`distributors.${zone}.address-1`} />

									<br />
									<FormattedMessage id={`distributors.${zone}.email-title`} />
									<FormattedMessage id={`distributors.${zone}.email`}>
										{(link) => (
											<a href={`mailto:${link}`} title={`Contact ${link}`}>
												<FormattedMessage id={`distributors.${zone}.email`} />
											</a>
										)}
									</FormattedMessage>
									<br />
									<FormattedMessage id={`distributors.${zone}.website-title`} />
									<FormattedMessage id={`distributors.${zone}.website`}>
										{(link) => (
											<a
												href={`${link}`}
												title="Website"
												target="_blank"
												rel="noopener noreferrer"
											>
												<FormattedMessage id={`distributors.${zone}.email`} />
											</a>
										)}
									</FormattedMessage>
									<br />
									<FormattedMessage id={`distributors.${zone}.fax-title`} />
									<FormattedMessage id={`distributors.${zone}.fax-formatted`}>
										{(link) => (
											<a href={`fax:${link}`} title="Fax">
												<FormattedMessage id={`distributors.${zone}.fax`} />
											</a>
										)}
									</FormattedMessage>
									<br />
									<FormattedMessage id={`distributors.${zone}.phone-1-title`} />
									<FormattedMessage id={`distributors.${zone}.phone-1-formatted`}>
										{(link) => (
											<a href={`phone:${link}`} title="Phone">
												<FormattedMessage id={`distributors.${zone}.phone-1`} />
											</a>
										)}
									</FormattedMessage>
									<br />
									<FormattedMessage id={`distributors.${zone}.phone-2-title`} />
									<FormattedMessage id={`distributors.${zone}.phone-2-formatted`}>
										{(link) => (
											<a href={`phone:${link}`} title="Phone">
												<FormattedMessage id={`distributors.${zone}.phone-2`} />
											</a>
										)}
									</FormattedMessage>
									<br />
									<FormattedMessage id={`distributors.${zone}.zones-title`} />
									<br />
									<FormattedMessage id={`distributors.${zone}.zones`} />
								</p>
							</div>
						))}
					</div>
				</div>
				<div className="container-grid">
					<div>
						{/* <p className="text-center">
							<a
								href="http://groupconstantinosphillisfa10.sitolocalweb.com/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<strong>
									<FormattedMessage id={`layout.misc.shop-title`} />
								</strong>
							</a>
						</p>
						<p className="text-center">
							<FormattedMessage id={`layout.misc.shop-subtitle`} />
						</p> */}
						<ShopOnline />
					</div>
				</div>
			</section>
		</Page>
	);
};

export default Distributors;
