import React from 'react';
import { withRouter } from 'react-router-dom';
import Link from './IntlLink';
import { FormattedMessage } from 'react-intl';
//other FormattedTime, FormattedRelative (for relative times) and FormattedMessage
//formatted date with locales example: https://codesandbox.io/s/pm9m08prkq
import LanguageMenu from './LanguageMenu';

const Menu = ({ match, isMobile, closeMobile, section }) => {
	const { language } = match.params;
	const locale = language || 'en';

	//console.log('section: ' + section); //Drilled from Header.js to get active section !section&&'active'
	//var currentLocation = window.location.pathname;
	//console.log('aaaaaaaa' + section);
	//className={condition ? value : undefined}
	return (
		<React.Fragment>
			<ul className="main-menu">
				<li className={!section ? 'active' : undefined}>
					<Link className={!isMobile ? 'menu-item' : ''} to="/" locale={locale}>
						<FormattedMessage id="layout.menu.home" />
					</Link>
				</li>
				<li className={section === 'production' ? 'active' : undefined}>
					<Link className={!isMobile ? 'menu-item' : ''} to="/production" locale={locale}>
						<FormattedMessage id="layout.menu.production" />
					</Link>
				</li>
				<li className={section === 'wines' ? 'active' : undefined}>
					<Link className={!isMobile ? 'menu-item' : ''} to="/wines" locale={locale}>
						<FormattedMessage id="layout.menu.wines" />
					</Link>
				</li>
				{/* <li>
					<a
						href="https://poderecollesanmassimo.it"
						title="Acquista Online - Online shop"
						target="_blank"
						className={!isMobile ? 'menu-item' : ''}
						rel="noopener noreferrer"
					>
						<FormattedMessage id="layout.menu.shop" />
					</a>
				</li> */}
				<li className={section === 'distributors' ? 'active' : undefined}>
					<Link className={!isMobile ? 'menu-item' : ''} to="/distributors" locale={locale}>
						<FormattedMessage id="layout.menu.distributors" />
					</Link>
				</li>
				<li className={section === 'bnb' ? 'active' : undefined}>
					<Link className={!isMobile ? 'menu-item' : ''} to="/bnb" locale={locale}>
						<FormattedMessage id="layout.menu.bnb" />
					</Link>
				</li>
				<li className={section === 'contact-us' ? 'active' : undefined}>
					<Link className={!isMobile ? 'menu-item' : ''} to="/contact-us" locale={locale}>
						<FormattedMessage id="layout.menu.contact-us" />
					</Link>
				</li>
			</ul>
			<ul className="language-menu">
				<LanguageMenu isMobile={isMobile} section={section} />
			</ul>
		</React.Fragment>
	);
};

export default withRouter(Menu);
