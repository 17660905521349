import React from 'react';
import { Link, useParams } from 'react-router-dom';

const LanguageMenu = ({ isMobile, section }) => {
	//locale to get active locale
	let { language } = useParams();

	//console.log(useParams());
	//{/* <div className="language-menu">{language === 'it' ? <Link to="/en">En</Link> : <Link to="/it">It</Link>}</div> */}
	// prettier-ignore

	return (
		<li className="language">
			{language === 'it' ? (
				<div>
					<span>IT | </span>
					<Link className={isMobile ? '' : 'menu-item'} to={section?'/en/' + section:'/en'}>
						EN
					</Link>
				</div>
			) : (
				<div>
					<Link className={isMobile ? '' : 'menu-item'} to={section?'/it/' + section:'/it'}>
						IT
					</Link>
					<span> | EN</span>
				</div>
			)}
		</li>
	);
};

export default LanguageMenu;
