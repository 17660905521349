import React, { useState, useEffect } from 'react';
import { withGoogleMap, withScriptjs, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import * as pcsmData from '../data/pcms-data.json';
import mapStyles from '../util/mapStyles';
//starting in Ottawa, coords taken from https://www.latlong.net/
const Map = (props) => {
	const [ selectedMarker, setSelectedMarker ] = useState(null);

	useEffect(() => {
		const listener = (e) => {
			if (e.key === 'Escape') {
				setSelectedMarker(null);
			}
		};
		window.addEventListener('keydown', listener);

		return () => {
			window.removeEventListener('keydown', listener);
		};
	}, []);

	return (
		<GoogleMap
			defaultZoom={12}
			defaultCenter={{ lat: 42.75215, lng: 13.93012 }}
			defaultOptions={{ styles: mapStyles }}
		>
			{pcsmData.features.map((distributor) => (
				<Marker
					key={distributor.properties.ID}
					position={{
						lat: distributor.geometry.coordinates[1],
						lng: distributor.geometry.coordinates[0]
					}}
					onClick={() => {
						setSelectedMarker(distributor);
					}}
					icon={{
						url: `/logo-marker.svg`,
						scaledSize: new window.google.maps.Size(55, 55)
					}}
				/>
			))}

			{selectedMarker && (
				<InfoWindow
					onCloseClick={() => {
						setSelectedMarker(null);
					}}
					position={{
						lat: selectedMarker.geometry.coordinates[1],
						lng: selectedMarker.geometry.coordinates[0]
					}}
				>
					<div>
						<h2>{selectedMarker.properties.NAME}</h2>
						<p>{selectedMarker.properties.TITLE}</p>
						<p>
							{selectedMarker.properties.ADDRESS}
							<br /> {selectedMarker.properties.ADDRESS1}
						</p>
					</div>
				</InfoWindow>
			)}
		</GoogleMap>
	);
};

const GMaps = (props) => {
	return (
		<div style={{ width: `${props.vwWidth}vw`, height: `${props.vhHeight}vh` }}>
			<MapWrapped
				isMarkerShown={false}
				googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBwF_TCcbmkctdkMfYgdAQKghv-kemq25A`}
				loadingElement={<div style={{ height: `100%` }} />}
				containerElement={<div style={{ height: `100%` }} />}
				mapElement={<div style={{ height: `100%` }} />}
				data={props.data}
			/>
		</div>
	);
};
//can put at the end of googleMapUrl as &key=yourKey
//or create .env.local and copy it: REACT_APP_GOOGLE_KEY = "AIzaSyAhoAoSQLSRs8ljQYGdXv6Uapaf2mX2iso";

const MapWrapped = withScriptjs(withGoogleMap(Map));

export default GMaps;
