import React, { useRef, useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import { useIntersection } from 'react-use';
//import useOnScreen from '../hooks/useOnScreen';
//import gsap from 'gsap/all';
import { fadeIn, fadeOut } from '../util/Animations';
import HomeHead from '../components/HomeHead';
import Page from '../templates/Page';

import HomeImg1 from '../images/home/home-1-min.jpg';
import HomeImg2 from '../images/home/home-3-min.jpg';
import HomeImg3 from '../images/home/home-4-min.jpg';

import parallaxImgXl from '../images/home/home-parallax-xl.jpg';
import parallaxImgLg from '../images/home/home-parallax-lg.jpg';
import parallaxImgMd from '../images/home/home-parallax-md.jpg';
import parallaxImgSm from '../images/home/home-parallax-sm.jpg';

import { ReactComponent as LogoConsorzioTutela } from '../assets/consorzio-tutela-colline-teramane.svg';
import LogoWow from '../images/prizes/medaglia-oro-wow-2020.png';

const Home = (props) => {
	//Responsive parallax
	//https://itnext.io/responsive-background-images-using-react-hooks-941af365ea1f
	const [ windowWidth, setWindowWidth ] = useState(window.innerWidth);
	const parallaxImg =
		windowWidth >= 650
			? windowWidth >= 1280 ? (windowWidth >= 1920 ? parallaxImgXl : parallaxImgLg) : parallaxImgMd
			: parallaxImgSm;

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener('resize', handleWindowResize);

		return () => {
			window.removeEventListener('resize', handleWindowResize);
		};
	}, []);

	//REFS
	let image1 = useRef(null);
	let image2 = useRef(null);
	let image3 = useRef(null);

	const tresholdPercentage = 0.6;
	// All the ref to be observed
	const intersectionImage1 = useIntersection(image1, {
		root: null,
		rootMargin: '20px',
		threshold: tresholdPercentage
	});
	const intersectionImage2 = useIntersection(image2, {
		root: null,
		rootMargin: '20px',
		threshold: tresholdPercentage
	});
	const intersectionImage3 = useIntersection(image3, {
		root: null,
		rootMargin: '200px',
		threshold: tresholdPercentage
	});

	//INTERSECTION OBSERVER TURNARY CHECK
	// checking to see when the vieport is visible to the user
	intersectionImage1 && intersectionImage1.intersectionRatio < tresholdPercentage
		? fadeOut('.image1-fadeIn') //not reached, so animate out
		: fadeIn('.image1-fadeIn'); //reached, so animate in
	intersectionImage2 && intersectionImage2.intersectionRatio < tresholdPercentage
		? fadeOut('.image2-fadeIn') //not reached, so animate out
		: fadeIn('.image2-fadeIn'); //reached, so animate in
	intersectionImage3 && intersectionImage3.intersectionRatio < tresholdPercentage
		? fadeOut('.image3-fadeIn') //not reached, so animate out
		: fadeIn('.image3-fadeIn'); //reached, so animate in

	return (
		<Page {...props}>
			<HomeHead />
			<section className="home-features">
				<div className="container-fluid reverse blk-bkg">
					<div className="text">
						<h1>
							<FormattedMessage id="home.title" />
						</h1>
						<h2>
							<FormattedMessage id="home.claim" />
						</h2>
						<a href="http://collineteramane.com/en/" target="_blank" rel="noopener noreferrer">
							<LogoConsorzioTutela className="consorzio-tutela-colline-teramane-logo" />
						</a>
						<div className="prize">
							<a
								href="https://wow.civiltadelbere.com/edizione-2020/medaglie-doro-2020-2/"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img src={LogoWow} alt="Wow Edizione 2020" />
							</a>
							<h3>
								<FormattedMessage id="home.prize" />
							</h3>
						</div>
						{/* 
						<br />
						<p className="claim">
							<FormattedMessage id="home.content-claim" />
						</p>
						*/}
					</div>
					<div className="image image1-fadeIn" ref={image1}>
						<img src={HomeImg1} alt="Podere Colle San Massimo" />
					</div>
				</div>

				<div className="container-fluid">
					<div className="text">
						<p>
							<FormattedMessage id="home.content-1" />
							<br />
							<FormattedMessage id="home.content-2" />
						</p>
					</div>
					<div className="image image2-fadeIn" ref={image2}>
						<img src={HomeImg2} alt="Podere Colle San Massimo" />
					</div>
				</div>
				<div className="container-fluid reverse blk-bkg">
					<div className="text">
						<p>
							<FormattedMessage id="home.content-3" />
						</p>
					</div>
					<div className="image image3-fadeIn" ref={image3}>
						<img src={HomeImg3} alt="Podere Colle San Massimo" />
					</div>
				</div>
			</section>
			<div className="parallax-section">
				<div className="parallax-child-section">
					<section className="fw-main-row frontlash" style={{ backgroundImage: `url(${parallaxImg})` }} />
				</div>
			</div>
			{/* <div className="parallax" style={{ backgroundImage: `url(${parallaxImg})` }} /> */}
		</Page>
	);
};

export default Home;
