import React from 'react';
import { FormattedMessage } from 'react-intl';

const ShopOnline = () => {
	return (
		<div>
			<p className="text-center">
				<strong>
					<FormattedMessage id={`layout.misc.shop-title`} />:
				</strong>
				<br />
				<br />
				<a
					href="http://www.poderecollesanmassimo.it"
					target="_blank"
					title="Podere Colle San Massimo"
					rel="noopener noreferrer"
				>
					www.poderecollesanmassimo.it
				</a>
			</p>
			<p className="text-center">
				<FormattedMessage id={`layout.misc.shop-subtitle`} />
			</p>
		</div>
	);
};

export default ShopOnline;
