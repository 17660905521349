import React, { useState } from 'react';
import { TimelineMax } from 'gsap';
import { withRouter } from 'react-router-dom';
import NavLink from './IntlLink';
import { FormattedMessage } from 'react-intl';
import Menu from './Menu';
import { ReactComponent as LogoMobile } from '../assets/logo-clear.svg';

const MobileMenu = ({ match }) => {
	// const { language } = match.params;
	// const locale = language || 'en';
	const [ isExpandable, setIsExpandable ] = useState(false);
	const expandNavWithAnim = () => {
		setIsExpandable(!isExpandable);

		const timeline = new TimelineMax();

		timeline.fromTo('.nav-open', 1, { x: '-100vw' }, { x: '0vw', ease: 'Power2.easeInOut' });
		timeline.fromTo(
			'.nav-open ul li, .logo-mobile',
			1,
			{ x: '-20px', opacity: 0 },
			{ x: '0px', opacity: 1, ease: 'Power2.easeInOut' },
			'-=.5'
		);
		timeline.fromTo('.bar>div', 1, { backgroundColor: '#1E1B11' }, { backgroundColor: '#ddc6bf' });
	};

	return (
		<div>
			<div className="fixed-nav" onClick={expandNavWithAnim}>
				<div className="bar">
					<div className="fa-line" />
					<div className="fa-line" />
					<div className="fa-line" />
				</div>
			</div>
			<div className={`nav-open ${isExpandable ? '' : 'nav-display'}`}>
				<div>
					<NavLink to="/">
						<FormattedMessage id={'layout.header'}>
							{(title) => <LogoMobile className="logo-mobile" title={title} alt={title} />}
						</FormattedMessage>
					</NavLink>
					<Menu isMobile={true} closeMobile={expandNavWithAnim} />
				</div>
			</div>
		</div>
	);
};
export default withRouter(MobileMenu);
