import React, { useLayoutEffect, useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
//import Routes from "../Routes";
import Header from '../components/Header';
import Footer from '../components/Footer';
const Layout = ({ children, ...props }) => {
	let loadingScreen = useRef(null);

	useEffect(() => {
		gsap.to(loadingScreen, 0.4, {
			delay: 0.4,
			top: '-110%',
			ease: 'expo.easeInOut'
		});
	}, []);

	const [ scroll, setScroll ] = useState({
		x: window.pageXOffset,
		y: window.pageYOffset
	});

	const handleScroll = () => {
		setScroll({
			x: window.pageXOffset,
			y: window.pageYOffset
		});
	};

	useLayoutEffect(() => {
		// Add scroll listener
		window.addEventListener('scroll', handleScroll);
		return () => {
			// Remove scroll listener
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);
	return (
		<React.Fragment>
			<div
				ref={(el) => {
					loadingScreen = el;
				}}
				className="loading-screen"
			/>
			<Header scroll={scroll} {...props} />
			<main>{children}</main>
			<Footer />
		</React.Fragment>
	);
};

export default Layout;
