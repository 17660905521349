import React from 'react';
/* export const Instagram = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21">
		<g fill="#EA281E" fillRule="evenodd">
			<path d="M10.5 0C7.648 0 7.29.012 6.17.063 5.054.114 4.29.292 3.623.551a5.146 5.146 0 00-1.86 1.211 5.147 5.147 0 00-1.21 1.86C.291 4.29.113 5.053.062 6.171.012 7.29 0 7.648 0 10.5c0 2.852.012 3.21.063 4.33.051 1.117.229 1.88.488 2.548.269.69.628 1.276 1.211 1.86.584.583 1.17.942 1.86 1.21.668.26 1.431.438 2.549.489 1.12.05 1.477.063 4.329.063 2.852 0 3.21-.012 4.33-.063 1.117-.051 1.88-.229 2.548-.488a5.146 5.146 0 001.86-1.211 5.147 5.147 0 001.21-1.86c.26-.668.438-1.431.489-2.549.051-1.12.063-1.477.063-4.329 0-2.852-.012-3.21-.063-4.33-.051-1.117-.229-1.88-.488-2.548a5.147 5.147 0 00-1.211-1.86 5.146 5.146 0 00-1.86-1.21c-.668-.26-1.431-.438-2.549-.489C13.71.012 13.352 0 10.5 0m0 1.892c2.804 0 3.136.01 4.243.061 1.024.047 1.58.218 1.95.362.49.19.84.418 1.207.785.367.368.595.717.785 1.207.144.37.315.926.362 1.95.05 1.107.061 1.44.061 4.243 0 2.804-.01 3.136-.061 4.243-.047 1.024-.218 1.58-.362 1.95-.19.49-.418.84-.785 1.207a3.252 3.252 0 01-1.207.785c-.37.144-.926.315-1.95.362-1.107.05-1.44.061-4.243.061-2.804 0-3.136-.01-4.243-.061-1.024-.047-1.58-.218-1.95-.362-.49-.19-.84-.418-1.207-.785a3.254 3.254 0 01-.785-1.207c-.144-.37-.315-.926-.362-1.95-.05-1.107-.061-1.44-.061-4.243 0-2.804.01-3.136.061-4.243.047-1.024.218-1.58.362-1.95.19-.49.418-.84.785-1.207a3.253 3.253 0 011.207-.785c.37-.144.926-.315 1.95-.362 1.107-.05 1.44-.061 4.243-.061" />
			<path d="M10.5 14.07a3.57 3.57 0 110-7.14 3.57 3.57 0 010 7.14m0-9.07a5.5 5.5 0 100 11 5.5 5.5 0 000-11" />
			<path d="M17 5a1 1 0 11-2 0 1 1 0 012 0" />
		</g>
	</svg>
); */
export const Youtube = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<g>
			<path
				style={{ fill: '#F61C0D' }}
				d="M365.257,67.393H95.744C42.866,67.393,0,110.259,0,163.137v134.728
		c0,52.878,42.866,95.744,95.744,95.744h269.513c52.878,0,95.744-42.866,95.744-95.744V163.137
		C461.001,110.259,418.135,67.393,365.257,67.393z M300.506,237.056l-126.06,60.123c-3.359,1.602-7.239-0.847-7.239-4.568V168.607
		c0-3.774,3.982-6.22,7.348-4.514l126.06,63.881C304.363,229.873,304.298,235.248,300.506,237.056z"
			/>
		</g>
	</svg>
);

export const Instagram = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
		<path
			style={{ fill: '#ffffff' }}
			d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
		/>
	</svg>
);
/* export const Instagram = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
		<style />
		<linearGradient
			gradientUnits="userSpaceOnUse"
			x1="-46"
			y1="634.1"
			x2="-32.9"
			y2="647.2"
			gradientTransform="matrix(32 0 0 -32 1519 20757)"
		>
			<stop offset="0" className="a" styles={{ stopColor: '#ffc107' }} />
			<stop offset="0.507" className="b" styles={{ stopColor: '#f44336' }} />
			<stop offset="0.99" className="c" styles={{ stopColor: '#9c27b0' }} />
		</linearGradient>
		<path
			d="M352 0H160C71.6 0 0 71.6 0 160v192c0 88.4 71.6 160 160 160h192c88.4 0 160-71.6 160-160V160C512 71.6 440.4 0 352 0zM464 352c0 61.8-50.2 112-112 112H160c-61.8 0-112-50.2-112-112V160C48 98.2 98.2 48 160 48h192c61.8 0 112 50.2 112 112V352z"
			fill="url(#SVGID_1_)"
		/>
		<linearGradient
			gradientUnits="userSpaceOnUse"
			x1="-42.3"
			y1="637.8"
			x2="-36.6"
			y2="643.5"
			gradientTransform="matrix(32 0 0 -32 1519 20757)"
		>
			<stop offset="0" className="a" />
			<stop offset="0.507" className="b" />
			<stop offset="0.99" className="c" />
		</linearGradient>
		<path
			d="M256 128c-70.7 0-128 57.3-128 128s57.3 128 128 128 128-57.3 128-128S326.7 128 256 128zM256 336c-44.1 0-80-35.9-80-80 0-44.1 35.9-80 80-80s80 35.9 80 80C336 300.1 300.1 336 256 336z"
			fill="url(#SVGID_2_)"
		/>
		<linearGradient
			gradientUnits="userSpaceOnUse"
			x1="-35.5"
			y1="644.6"
			x2="-34.8"
			y2="645.3"
			gradientTransform="matrix(32 0 0 -32 1519 20757)"
		>
			<stop offset="0" className="a" />
			<stop offset="0.507" className="b" />
			<stop offset="0.99" className="c" />
		</linearGradient>
		<circle cx="393.6" cy="118.4" r="17.1" fill="url(#SVGID_3_)" />
	</svg>
); */
export const Facebook = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 24">
		<path
			d="M3.625 1.236C4.544.346 5.85.044 7.085.006 8.387-.004 9.691.003 10.995.004c.005 1.4.006 2.802 0 4.203-.843-.001-1.687.002-2.53-.001-.534-.034-1.083.378-1.18.918-.013.937-.004 1.875-.004 2.813 1.236.005 2.472-.002 3.708.003a53.297 53.297 0 01-.45 4.05c-1.091.011-2.183 0-3.275.007-.01 4 .005 8-.008 12-1.622.006-3.244-.004-4.866.004-.03-4 .002-8.003-.017-12.004-.791-.008-1.583.006-2.374-.007.003-1.344.001-2.689.001-4.033.791-.01 1.582.002 2.373-.006.024-1.307-.024-2.616.025-3.922.08-1.035.466-2.077 1.226-2.792z"
			fill="#2D88FF"
			fillRule="nonzero"
		/>
	</svg>
);
export const Whatsapp = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="48px" height="48px">
		<path
			fill="#fff"
			d="M4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98c-0.001,0,0,0,0,0h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303z"
		/>
		<path
			fill="#fff"
			d="M4.868,43.803c-0.132,0-0.26-0.052-0.355-0.148c-0.125-0.127-0.174-0.312-0.127-0.483l2.639-9.636c-1.636-2.906-2.499-6.206-2.497-9.556C4.532,13.238,13.273,4.5,24.014,4.5c5.21,0.002,10.105,2.031,13.784,5.713c3.679,3.683,5.704,8.577,5.702,13.781c-0.004,10.741-8.746,19.48-19.486,19.48c-3.189-0.001-6.344-0.788-9.144-2.277l-9.875,2.589C4.953,43.798,4.911,43.803,4.868,43.803z"
		/>
		<path
			fill="#cfd8dc"
			d="M24.014,5c5.079,0.002,9.845,1.979,13.43,5.566c3.584,3.588,5.558,8.356,5.556,13.428c-0.004,10.465-8.522,18.98-18.986,18.98h-0.008c-3.177-0.001-6.3-0.798-9.073-2.311L4.868,43.303l2.694-9.835C5.9,30.59,5.026,27.324,5.027,23.979C5.032,13.514,13.548,5,24.014,5 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974C24.014,42.974,24.014,42.974,24.014,42.974 M24.014,4C24.014,4,24.014,4,24.014,4C12.998,4,4.032,12.962,4.027,23.979c-0.001,3.367,0.849,6.685,2.461,9.622l-2.585,9.439c-0.094,0.345,0.002,0.713,0.254,0.967c0.19,0.192,0.447,0.297,0.711,0.297c0.085,0,0.17-0.011,0.254-0.033l9.687-2.54c2.828,1.468,5.998,2.243,9.197,2.244c11.024,0,19.99-8.963,19.995-19.98c0.002-5.339-2.075-10.359-5.848-14.135C34.378,6.083,29.357,4.002,24.014,4L24.014,4z"
		/>
		<path
			fill="#40c351"
			d="M35.176,12.832c-2.98-2.982-6.941-4.625-11.157-4.626c-8.704,0-15.783,7.076-15.787,15.774c-0.001,2.981,0.833,5.883,2.413,8.396l0.376,0.597l-1.595,5.821l5.973-1.566l0.577,0.342c2.422,1.438,5.2,2.198,8.032,2.199h0.006c8.698,0,15.777-7.077,15.78-15.776C39.795,19.778,38.156,15.814,35.176,12.832z"
		/>
		<path
			fill="#fff"
			fillRule="evenodd"
			d="M19.268,16.045c-0.355-0.79-0.729-0.806-1.068-0.82c-0.277-0.012-0.593-0.011-0.909-0.011c-0.316,0-0.83,0.119-1.265,0.594c-0.435,0.475-1.661,1.622-1.661,3.956c0,2.334,1.7,4.59,1.937,4.906c0.237,0.316,3.282,5.259,8.104,7.161c4.007,1.58,4.823,1.266,5.693,1.187c0.87-0.079,2.807-1.147,3.202-2.255c0.395-1.108,0.395-2.057,0.277-2.255c-0.119-0.198-0.435-0.316-0.909-0.554s-2.807-1.385-3.242-1.543c-0.435-0.158-0.751-0.237-1.068,0.238c-0.316,0.474-1.225,1.543-1.502,1.859c-0.277,0.317-0.554,0.357-1.028,0.119c-0.474-0.238-2.002-0.738-3.815-2.354c-1.41-1.257-2.362-2.81-2.639-3.285c-0.277-0.474-0.03-0.731,0.208-0.968c0.213-0.213,0.474-0.554,0.712-0.831c0.237-0.277,0.316-0.475,0.474-0.791c0.158-0.317,0.079-0.594-0.04-0.831C20.612,19.329,19.69,16.983,19.268,16.045z"
			clipRule="evenodd"
		/>
	</svg>
);
export const PhoneIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -30 100 120">
		<g>
			<path
				fill="#ddc6bf"
				fillRule="evenodd"
				d="M85.4,14.6C75.6,4.9,62.8,0,50,0C37.2,0,24.4,4.9,14.6,14.6c-19.5,19.5-19.5,51.2,0,70.7C24.4,95.1,37.2,100,50,100   c12.8,0,25.6-4.9,35.4-14.6C104.9,65.8,104.9,34.2,85.4,14.6z M79.2,79.2C71.4,86.9,61,91.2,50,91.2c-11,0-21.4-4.3-29.2-12.1   c-16.1-16.1-16.1-42.2,0-58.3C28.6,13,39,8.8,50,8.8c11,0,21.4,4.3,29.2,12.1C95.2,36.9,95.2,63.1,79.2,79.2z"
			/>
			<path
				fill="#ddc6bf"
				fillRule="evenodd"
				d="M68.3,56.8c-1.9-1.9-4.4-1.9-6.3,0c-1.5,1.5-3,2.9-4.4,4.4c-0.1,0.1-0.2,0.2-0.3,0.2l-1,1c-0.3,0.2-0.6,0.2-0.9,0   c-0.9-0.5-2-0.9-2.9-1.5c-4.3-2.7-7.9-6.1-11-10c-1.6-1.9-3-4-4-6.3c-0.2-0.4-0.2-0.7,0.1-1l1-1c0.1-0.1,0.1-0.1,0.2-0.2   c0.4-0.4,0.9-0.9,1.3-1.3c1-1,2-2,3-3.1c2-2,2-4.4,0-6.5c-1.1-1.2-2.3-2.3-3.5-3.5c-1.2-1.2-2.4-2.4-3.6-3.6   c-1.9-1.9-4.4-1.9-6.3,0c-1.5,1.5-2.9,3-4.4,4.4c-1.4,1.3-2.1,2.9-2.3,4.9c-0.2,3.1,0.5,6,1.6,8.8c2.2,5.9,5.5,11.1,9.5,15.9   c5.4,6.5,11.9,11.6,19.5,15.3c3.4,1.7,7,2.9,10.8,3.1c2.6,0.1,5-0.5,6.8-2.6c1.3-1.4,2.7-2.7,4-4.1c2-2,2-4.4,0-6.4   C73.1,61.5,70.7,59.1,68.3,56.8z"
			/>
		</g>
	</svg>
);

export const Vimeo = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 18">
		<path
			d="M20.99 4.164c-.094 2.026-1.521 4.8-4.283 8.32C13.852 16.162 11.436 18 9.461 18c-1.225 0-2.261-1.12-3.107-3.359l-1.695-6.16C4.03 6.244 3.357 5.123 2.636 5.123c-.158 0-.707.328-1.649.98L0 4.842a259.826 259.826 0 003.064-2.709C4.446.95 5.485.328 6.176.265c1.634-.156 2.64.951 3.018 3.32.408 2.557.69 4.147.849 4.77.472 2.121.99 3.18 1.556 3.18.439 0 1.1-.687 1.98-2.064.879-1.376 1.35-2.422 1.413-3.142.126-1.187-.345-1.782-1.413-1.782-.503 0-1.021.114-1.554.34 1.032-3.349 3.003-4.976 5.914-4.883 2.158.063 3.175 1.45 3.05 4.16"
			fill="#EA281E"
			fillRule="evenodd"
		/>
	</svg>
);

export const ArrowIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 101 57">
		<path
			d="M33 34H0V24h81.429L66 7.884 73.548 0l19.877 20.763.027-.029L101 28.618 73.829 57l-7.548-7.884L80.753 34H33z"
			fill="#FFF"
			fillRule="evenodd"
		/>
	</svg>
);

export const OrsoeApe = () => {
	return (
		<svg id="orsoeape" data-name="Livello 1 copia" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 311.36 179.75">
			<defs>
				<style />
			</defs>
			<title>orsoeape</title>
			<g id="Livello_3" data-name="Livello 3">
				<path className="cls-1" d="M637.66,427" transform="translate(-460.77 -333.42)" />
				<line className="cls-1" x1="176.89" y1="93.55" x2="177.95" y2="105.19" />
				<line className="cls-1" x1="194.5" y1="105.19" x2="195.68" y2="92.25" />
				<circle className="cls-1" cx="175.61" cy="90.42" r="3.13" />
				<circle className="cls-1" cx="197.2" cy="90.42" r="3.13" />
			</g>
			<g id="Livello_4" data-name="Livello 4">
				<path className="cls-2" d="M644.88,453.55" transform="translate(-460.77 -333.42)" />
				<path
					className="cls-1"
					d="M666.73,455.43c0-5.54-4.29-15.17-11.46-16.82a22.22,22.22,0,0,0-4-.2l-9.62.1-2.92.09c-1.57.81-10.55,3.86-11.33,15.38a60.22,60.22,0,0,0-.3,7.42l.39,32.7h17.19V482.29l1.27-2.15,3.41-.1,1.47,2q0,6,.1,12h16.2C667,480,666.86,469.49,666.73,455.43Zm-14,11.22h-9.07V456.5a5.89,5.89,0,0,1,4.53-2.95,4.72,4.72,0,0,1,4.54,3.41Z"
					transform="translate(-460.77 -333.42)"
				/>
			</g>
			<path
				className="cls-3"
				d="M560.6,349.46l2.58-1.8c2.45-3.5,2.53-3.09,2.72-6.77s-7.32-9.7-11.48-6.64c-3.59,2.64-3.9,5.41-5,8.23a31,31,0,0,0-10.85-2,30.39,30.39,0,0,0-12.15,2.62l-.67-4.33c-.82-2.89-2.58-4.14-5.25-4.71-3.79-.8-7,2.63-8.24,6-1.41,3.75,1.36,7.23,2.09,8.13a9.64,9.64,0,0,0,1.73,1.67c-2.54,2.69-11.45,12.66-11,26.72.57,16.24,13.44,36.11,33.45,36.11,18.47,0,33.45-16.17,33.45-36.11A37.29,37.29,0,0,0,560.6,349.46ZM539.22,399c-11.47,0-20.77-10-20.77-22.42s9.3-22.42,20.77-22.42S560,364.22,560,376.6,550.69,399,539.22,399Z"
				transform="translate(-460.77 -333.42)"
			/>
			<path
				className="cls-4"
				d="M582.24,372.46c0-1.72-.14-3.25-.25-4.57h7.68c.12,1,.2,2.23.26,3.58s.09,2.46.09,3.33h.26a14.13,14.13,0,0,1,5.05-5.83,13.18,13.18,0,0,1,7.47-2.2,12.61,12.61,0,0,1,3.19.34l-.34,7.51a15.75,15.75,0,0,0-3.72-.43,11.23,11.23,0,0,0-5.09,1.08,10.4,10.4,0,0,0-3.59,2.89,12.25,12.25,0,0,0-2.11,4.19,17.76,17.76,0,0,0-.69,5v21.5h-8.12V376.7C582.33,375.6,582.3,374.19,582.24,372.46Z"
				transform="translate(-460.77 -333.42)"
			/>
			<path
				className="cls-4"
				d="M635.7,378.25a10.67,10.67,0,0,0-3.89-3.67,11.15,11.15,0,0,0-5.61-1.43,13.07,13.07,0,0,0-2.77.31,8.13,8.13,0,0,0-2.46,1,5.2,5.2,0,0,0-1.72,1.64,4.34,4.34,0,0,0-.65,2.46,4.09,4.09,0,0,0,2.33,3.84,28.32,28.32,0,0,0,7,2.29,44,44,0,0,1,5.43,1.64,17.08,17.08,0,0,1,4.36,2.37,10.2,10.2,0,0,1,2.9,3.42,9.92,9.92,0,0,1,1,4.66,12.54,12.54,0,0,1-1.38,6.13,11.88,11.88,0,0,1-3.67,4.1,14.94,14.94,0,0,1-5.22,2.29,26.35,26.35,0,0,1-6,.69,24,24,0,0,1-9.11-1.81,17.85,17.85,0,0,1-7.38-5.53l5.79-4.92a13.28,13.28,0,0,0,4.53,4.06,12.68,12.68,0,0,0,6.35,1.64,14.48,14.48,0,0,0,3-.3,8.78,8.78,0,0,0,2.59-1,5,5,0,0,0,2.54-4.66,4.48,4.48,0,0,0-2.72-4.23,36.11,36.11,0,0,0-8-2.59c-1.39-.35-2.78-.76-4.19-1.25a14.1,14.1,0,0,1-3.84-2,10.2,10.2,0,0,1-2.81-3.24,9.75,9.75,0,0,1-1.08-4.79,11.26,11.26,0,0,1,1.34-5.66,11.7,11.7,0,0,1,3.5-3.89,15.38,15.38,0,0,1,4.92-2.24,22.05,22.05,0,0,1,5.7-.73,21.7,21.7,0,0,1,8.5,1.72,14.1,14.1,0,0,1,6.35,5Z"
				transform="translate(-460.77 -333.42)"
			/>
			<path
				className="cls-4"
				d="M693.38,388.27a22,22,0,0,1-1.69,8.72,20.6,20.6,0,0,1-4.66,6.86,20.94,20.94,0,0,1-7,4.49,24.56,24.56,0,0,1-17.44,0,20.34,20.34,0,0,1-7-4.49,21,21,0,0,1-4.62-6.86,22,22,0,0,1-1.69-8.72,21.68,21.68,0,0,1,1.69-8.68,20.61,20.61,0,0,1,4.62-6.78,20.85,20.85,0,0,1,7-4.45,24.71,24.71,0,0,1,17.44,0,21.48,21.48,0,0,1,7,4.45,20.27,20.27,0,0,1,4.66,6.78A21.68,21.68,0,0,1,693.38,388.27Zm-8.47,0a17.16,17.16,0,0,0-.91-5.6,13.94,13.94,0,0,0-2.64-4.69,12.65,12.65,0,0,0-4.29-3.23,14.84,14.84,0,0,0-11.69,0,12.85,12.85,0,0,0-4.29,3.23,14.29,14.29,0,0,0-2.64,4.69,17.69,17.69,0,0,0,0,11.2,14.8,14.8,0,0,0,2.64,4.73,12.45,12.45,0,0,0,4.29,3.27,14.72,14.72,0,0,0,11.69,0,12.26,12.26,0,0,0,4.29-3.27,14.43,14.43,0,0,0,2.64-4.73A17.16,17.16,0,0,0,684.91,388.31Z"
				transform="translate(-460.77 -333.42)"
			/>
			<path
				className="cls-4"
				d="M548.67,472.93v1.38a10.7,10.7,0,0,1-.09,1.39H516a12,12,0,0,0,1.16,4.84,12.79,12.79,0,0,0,2.9,3.95,13.42,13.42,0,0,0,4.18,2.61,14.65,14.65,0,0,0,12.22-.87,14.64,14.64,0,0,0,4.75-4.4l5.7,4.57a20.36,20.36,0,0,1-7.73,6.41,24.84,24.84,0,0,1-18.52.5,19.6,19.6,0,0,1-11.31-11.14,22.93,22.93,0,0,1-1.64-8.81,23.41,23.41,0,0,1,1.6-8.76,20.13,20.13,0,0,1,4.45-6.87,20.59,20.59,0,0,1,6.75-4.44,23.16,23.16,0,0,1,16.58-.13,17,17,0,0,1,6.23,4.23,19,19,0,0,1,3.93,6.69A26.34,26.34,0,0,1,548.67,472.93Zm-8.29-3.28a15.48,15.48,0,0,0-.82-4.53,10.37,10.37,0,0,0-2.16-3.67,10,10,0,0,0-3.54-2.46,12.46,12.46,0,0,0-5-.91A13,13,0,0,0,524,459a12.59,12.59,0,0,0-4,2.46,12.93,12.93,0,0,0-2.76,3.67,11.6,11.6,0,0,0-1.25,4.53Z"
				transform="translate(-460.77 -333.42)"
			/>
			<path className="cls-4" d="M492.6,371.26h-6.83l-1.23-19.79h8Z" transform="translate(-460.77 -333.42)" />
			<path className="cls-4" d="M469.39,409.24h-8.12V344h8.12Z" transform="translate(-460.77 -333.42)" />
			<path className="cls-4" d="M613.25,457.12h-6.82l-1.24-19.79h8Z" transform="translate(-460.77 -333.42)" />
			<path className="cls-4" d="M590,495.1h-8.12V429.82H590Z" transform="translate(-460.77 -333.42)" />
			<path
				className="cls-4"
				d="M686.75,512.67h-8.12V451h8v6.39h.26a15.19,15.19,0,0,1,5.81-5.39,18.19,18.19,0,0,1,8.92-2.12,20.58,20.58,0,0,1,8.31,1.64,19.2,19.2,0,0,1,6.46,4.58,20.74,20.74,0,0,1,4.18,6.86,24.07,24.07,0,0,1,1.46,8.42,24.34,24.34,0,0,1-1.46,8.46,20.87,20.87,0,0,1-4.14,6.91,19,19,0,0,1-6.46,4.62,20.8,20.8,0,0,1-8.52,1.69,18.62,18.62,0,0,1-8.27-1.9,14.92,14.92,0,0,1-6.12-5.36h-.26Zm26.94-41.28a18,18,0,0,0-.86-5.52,14.13,14.13,0,0,0-2.59-4.75,12.34,12.34,0,0,0-4.28-3.28,13.53,13.53,0,0,0-5.91-1.21,13,13,0,0,0-5.66,1.21,13.33,13.33,0,0,0-4.36,3.28,15,15,0,0,0-2.8,4.75,16.24,16.24,0,0,0,0,11.22,14.47,14.47,0,0,0,2.8,4.71,13.8,13.8,0,0,0,4.36,3.24A14.27,14.27,0,0,0,706,485a12.82,12.82,0,0,0,4.28-3.32,14.23,14.23,0,0,0,2.59-4.75A18.06,18.06,0,0,0,713.69,471.39Z"
				transform="translate(-460.77 -333.42)"
			/>
			<path
				className="cls-4"
				d="M771.63,471.13v1.39a12.77,12.77,0,0,1-.08,1.38H739a12.13,12.13,0,0,0,1.17,4.84,12.76,12.76,0,0,0,2.89,3.95,14.32,14.32,0,0,0,16.41,1.74,14.85,14.85,0,0,0,4.75-4.4l5.7,4.57a20.43,20.43,0,0,1-7.73,6.41,24.74,24.74,0,0,1-18.53.5,19.94,19.94,0,0,1-6.82-4.36,20.28,20.28,0,0,1-4.49-6.78,22.89,22.89,0,0,1-1.64-8.81,23.61,23.61,0,0,1,1.6-8.76,19.91,19.91,0,0,1,11.21-11.31,23.14,23.14,0,0,1,16.57-.13,16.9,16.9,0,0,1,6.23,4.23,19.15,19.15,0,0,1,3.94,6.69A26.6,26.6,0,0,1,771.63,471.13Zm-8.29-3.28a15.48,15.48,0,0,0-.82-4.53,10.46,10.46,0,0,0-2.16-3.67,10,10,0,0,0-3.54-2.46,12.41,12.41,0,0,0-5-.91,13,13,0,0,0-4.88.91,12.43,12.43,0,0,0-4,2.46,13.1,13.1,0,0,0-2.77,3.67,11.77,11.77,0,0,0-1.25,4.53Z"
				transform="translate(-460.77 -333.42)"
			/>
		</svg>
	);
};
