//GSAP ANIMATIONS
import gsap from 'gsap/all';
// Animation for fading in
//stagger is run the fade in animation for the first element and then after 0.3 seconds run for the second element
const fadeIn = (element) => {
	gsap.to(element, 1, {
		opacity: 1,
		y: -10,
		ease: 'power4.out',
		stagger: {
			amount: 0.3
		}
	});
};

// Animation for fading out
const fadeOut = (element) => {
	//comment all this if we don't want the animation to reload each time, or set the initial opacity to zero in the css (.sectionSecond>.inner elements)
	gsap.to(element, 1, {
		opacity: 0,
		y: 50,
		ease: 'power4.out'
	});
	//
};

export { fadeIn, fadeOut };
