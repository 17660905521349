import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
//import { injectIntl } from 'react-intl';
import Home from './pages/Home';
import Production from './pages/Production';
import Wines from './pages/Wines';
import Distributors from './pages/Distributors';
import BnB from './pages/BnB';
import ContactUs from './pages/ContactUs';
import { withProps, compose } from 'recompose';

// <Redirect exact from="/" to="/en/" />

const Routes = (props) => {
	const enhance = compose(withProps({ ...props }));

	return (
		<React.Fragment>
			<Switch>
				<Route exact path="/:language/" component={enhance(Home)} />
				<Route exact path="/:language/production" component={enhance(Production)} />
				<Route exact path="/:language/wines" component={enhance(Wines)} />
				<Route exact path="/:language/distributors" component={enhance(Distributors)} />
				<Route exact path="/:language/bnb" component={enhance(BnB)} />
				<Route exact path="/:language/contact-us" component={enhance(ContactUs)} />
				<Redirect exact from="/" to="/it/" />
			</Switch>
		</React.Fragment>
	);
};

export default Routes;
