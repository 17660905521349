import React, { useEffect } from 'react';

//for scroll to top each page change
import { useLocation } from 'react-router-dom';

import Routes from './Routes';
import './styles/App.scss';

//for scroll to top each page change
const ScrollToTop = () => {
	const { pathname } = useLocation();

	useEffect(
		() => {
			window.scrollTo(0, 0);
		},
		[ pathname ]
	);

	return null;
};

const App = () => {
	return (
		<React.Fragment>
			<ScrollToTop />
			<Routes />
		</React.Fragment>
	);
};

export default App;
