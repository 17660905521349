import React, { useRef, useEffect, useState, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
//import 'intersection-observer';

import Page from '../templates/Page';

import ShopOnline from '../components/ShopOnline';
//images assets
import WinesImg1 from '../images/wines/wines1.jpg';
import WinesImg3 from '../images/wines/wines3.jpg';

import imgColleregina from '../images/wines/colleregina.jpg';
import imgColledellorso from '../images/wines/colledellorso.jpg';
import imgAmore from '../images/wines/amore.jpg';
import imgAmoreCerasuolo from '../images/wines/amore_cerasuolo.jpg';
import imgCollerosso from '../images/wines/collerosso.jpg';
import imgTerrabruna from '../images/wines/terrabruna.jpg';
import imgOlio from '../images/wines/olio.jpg';
import parallaxImg from '../images/wines/wine-parallax.jpg';
import LogoWow from '../images/prizes/medaglia-oro-wow-2020.png';

const getDimensions = (ele) => {
	const { height } = ele.getBoundingClientRect();
	const offsetTop = ele.offsetTop;
	const offsetBottom = offsetTop + height;

	return {
		height,
		offsetTop,
		offsetBottom
	};
};

const scrollTo = (ele) => {
	ele.scrollIntoView({
		behavior: 'smooth',
		block: 'start'
	});
};

const Wines = (props) => {
	const [ visibleSection, setVisibleSection ] = useState();

	const headerRef = useRef(null);
	const collereginaRef = useRef(null);
	const colledellorsoRef = useRef(null);
	const amoreRef = useRef(null);
	const collerossoRef = useRef(null);
	const terrabrunaRef = useRef(null);
	const olioRef = useRef(null);

	/* const sectionRefs = [
		{ id: 'colleregina', section: 'Colle Regina', ref: collereginaRef, image: imgColleregina },
		{ id: 'colledellorso', section: "Colle dell'Orso", ref: colledellorsoRef, image: imgColledellorso },
		{ id: 'amore', section: 'Amore', ref: amoreRef, image: imgAmore },
		{ id: 'collerosso', section: 'Colle Rosso', ref: collerossoRef, image: imgCollerosso },
		{ id: 'terrabruna', section: 'Terra Bruna', ref: terrabrunaRef, image: imgTerrabruna },
		{ id: 'olio', section: 'Olio', ref: olioRef, image: imgOlio }
	]; */
	const sectionRefs = useMemo(()=>{
		return [
			{ id: 'colleregina', section: 'Colle Regina', ref: collereginaRef, image: imgColleregina },
			{ id: 'colledellorso', section: "Colle dell'Orso", ref: colledellorsoRef, image: imgColledellorso },
			{ id: 'amore', section: 'Amore', ref: amoreRef, image: imgAmore },
			{ id: 'collerosso', section: 'Colle Rosso', ref: collerossoRef, image: imgCollerosso },
			{ id: 'terrabruna', section: 'Terra Bruna', ref: terrabrunaRef, image: imgTerrabruna },
			{ id: 'olio', section: 'Olio', ref: olioRef, image: imgOlio }
		];
	},[])

	useEffect(
		() => {
			const handleScroll = (section) => {
				const { height: headerHeight } = getDimensions(headerRef.current);
				const scrollPosition = window.scrollY + headerHeight;

				const selected = sectionRefs.find(({ section, ref }) => {
					const ele = ref.current;
					if (ele) {
						const { offsetBottom, offsetTop } = getDimensions(ele);
						return scrollPosition > offsetTop && scrollPosition < offsetBottom;
					}
					return false;
				});

				if (selected && selected.id !== visibleSection) {
					setVisibleSection(selected.id);
				} else if (!selected && visibleSection) {
					setVisibleSection(undefined);
				}
			};

			handleScroll();
			window.addEventListener('scroll', handleScroll);
			return () => {
				window.removeEventListener('scroll', handleScroll);
			};
		},
		[ visibleSection, sectionRefs ]
	);

	return (
		<Page {...props}>
			<section className="wines-features">
				<div className="container-fluid">
					<div className="text">
						<h2>
							<FormattedMessage id="wines.title-main" />
						</h2>
						<p className="text-justify">
							<FormattedMessage id="wines.content-main" />
						</p>
						<div className="prize">
							<a
								href="https://wow.civiltadelbere.com/edizione-2020/medaglie-doro-2020-2/"
								title="WOW International Prize 2020 - Terra Bruna, Colline Teramane Montepulciano d’Abruzzo Docg 2015"
								target="_blank"
								rel="noopener noreferrer"
							>
								<img src={LogoWow} alt="Wow Edizione 2020" />
							</a>
							<p>
								<FormattedMessage id="home.prize" />
							</p>
						</div>
					</div>
					<div className="image-small">
						<img src={WinesImg1} alt="Vini - Wines" />
					</div>
				</div>
				{/* <div className="container-fluid reverse">
					<div className="text">
						<h2>
							<FormattedMessage id="wines.title-area" />
						</h2>
						<p className="text-justify">
							<FormattedMessage id="wines.content-2" />
						</p>
					</div>
					<div className="image">
						<img src={WinesImg2} alt="Vini - Wines" />
					</div>
				</div> */}
				<div className="container-fluid reverse">
					<div className="text">
						<h1>
							<FormattedMessage id="wines.title-bio" />
						</h1>
						<p className="text-justify">
							<FormattedMessage id="wines.content-bio-1" />
						</p>
						<h2>
							<FormattedMessage id="wines.content-bio-2" />
						</h2>

						<ShopOnline />
						{/* <p className="text-center">
							<strong>
								<FormattedMessage id={`layout.misc.shop-title`} />:
							</strong>
							<br />
							<br />
							<a
								href="http://www.poderecollesanmassimo.it"
								target="_blank"
								title="Podere Colle San Massimo"
								rel="noopener noreferrer"
							>
								www.poderecollesanmassimo.it
							</a>
						</p> 
						<p className="text-center">
							<FormattedMessage id={`layout.misc.shop-subtitle`} />
						</p>*/}
					</div>
					<div className="image">
						<img src={WinesImg3} alt="Vini - Wines" />
					</div>
				</div>
			</section>

			<div className="parallax-section">
				<div className="parallax-child-section">
					<section
						className="fw-main-row frontlash bg-cover"
						style={{ backgroundImage: `url(${parallaxImg})` }}
					/>
				</div>
			</div>

			<div className="sticky">
				<div className="header" ref={headerRef}>
					{sectionRefs.map((section) => (
						<button
							type="button"
							className={`header_link ${visibleSection === section.id ? 'selected' : ''}`}
							onClick={() => {
								scrollTo(section.ref.current);
							}}
							key={section.id}
						>
							{section.section}
						</button>
					))}
				</div>
			</div>

			{sectionRefs.map((section) => (
				<div key={section.id}>
					<div className="slide" title={section.section} ref={section.ref}>
						<div className="card">
							{section.image && (
								<div className="card-img" style={{ backgroundImage: `url(${section.image})` }} />
							)}
							<div className="card-content">
								<p className="card-theme">poderecollesanmassimo</p>
								<h2 className="card-header">{section.section}</h2>
								<p className="card-theme">
									<FormattedMessage id={`wines.${section.id}.content-product`} />
								</p>
								<br />
								{section.id === 'olio' ? (
									<div className="card-para">
										<FormattedMessage id={`wines.${section.id}.title`} />
										<br />
										<FormattedMessage id={`wines.${section.id}.content`} />
									</div>
								) : (
									<div className="card-para">
										<strong>
											<FormattedMessage id="wines.varietals" />
										</strong>
										<FormattedMessage id={`wines.${section.id}.varietals`} />
										<br />
										<strong>
											<FormattedMessage id="wines.vineyard" />
										</strong>
										<FormattedMessage id={`wines.${section.id}.vineyard`} />
										<br />
										<strong>
											<FormattedMessage id="wines.soil" />
										</strong>
										<FormattedMessage id={`wines.${section.id}.soil`} />
										<br />
										<strong>
											<FormattedMessage id="wines.harvest" />
										</strong>
										<FormattedMessage id={`wines.${section.id}.harvest`} />
										<br />
										<strong>
											<FormattedMessage id="wines.grapeyeld" />
										</strong>
										<FormattedMessage id={`wines.${section.id}.grapeyeld`} />
										<br />
										<strong>
											<FormattedMessage id="wines.vinification" />
										</strong>
										<FormattedMessage id={`wines.${section.id}.vinification`} />
										<br />
										<strong>
											<FormattedMessage id="wines.aging" />
										</strong>
										<FormattedMessage id={`wines.${section.id}.aging`} />
										<br />
										<strong>
											<FormattedMessage id="wines.characteristics" />
										</strong>
										<FormattedMessage id={`wines.${section.id}.characteristics`} />
										<br />
										<strong>
											<FormattedMessage id="wines.bouquet" />
										</strong>
										<FormattedMessage id={`wines.${section.id}.bouquet`} />
										<br />
										<strong>
											<FormattedMessage id="wines.taste" />
										</strong>
										<FormattedMessage id={`wines.${section.id}.taste`} />
										<br />
										<strong>
											<FormattedMessage id="wines.abv" />
										</strong>
										<FormattedMessage id={`wines.${section.id}.abv`} />
										<br />
										<strong>
											<FormattedMessage id="wines.foodpairing" />
										</strong>
										<FormattedMessage id={`wines.${section.id}.foodpairing`} />
										<br />
										<strong>
											<FormattedMessage id="wines.bestservedat" />
										</strong>
										<FormattedMessage id={`wines.${section.id}.bestservedat`} />
									</div>
								)}
							</div>
						</div>
					</div>
					{section.id === 'amore' && (
						<section className="wines-features">
							<div className="container-fluid">
								<div className="text">
									<h2 className="text-center">
										<FormattedMessage id="wines.cerasuolo-title" />
									</h2>
									<p className="text-justify">
										<FormattedMessage id="wines.cerasuolo-content" />
									</p>
								</div>
								<div className="image-small">
									<img src={imgAmoreCerasuolo} alt="Amore Cerasuolo D.O.C." />
								</div>
							</div>
						</section>
					)}
				</div>
			))}
		</Page>
	);
};

export default Wines;
