import React, { useRef, useState } from 'react';
import { useIntersection } from 'react-use';
import Page from '../templates/Page';
import { FormattedMessage } from 'react-intl';
import ProductionImg1 from '../images/production/production1.jpg';
import ProductionImg3 from '../images/production/production3.jpg';
import ProductionImg5 from '../images/production/production5.jpg';
import ParallaxImg from '../images/production/production-parallax-1.jpg';
import videoThumb from '../images/bnb/video-2-screen.jpg';
import LogoWow from '../images/prizes/medaglia-oro-wow-2020.png';

const scrollTo = (ele) => {
	setTimeout(function() {
		ele.scrollIntoView({
			behavior: 'smooth',
			block: 'start'
		});
	}, 1000);
};

const Production = (props) => {
	const videoRef = useRef(null);
	const contentRef = useRef(null);
	const [ isVideoLoaded, setIsVideoLoaded ] = useState(false);
	const desktopVideoMp4 = 'https://www.poderecollesanmassimo.com/assets/video/video-1-1080p.mp4';
	const tabletVideoMp4 = 'https://www.poderecollesanmassimo.com/assets/video/video-1-720p.mp4';
	const mobileVideoMp4 = 'https://www.poderecollesanmassimo.com/assets/video/video-1-480p.mp4';

	const desktopVideoWebm = 'https://www.poderecollesanmassimo.com/assets/video/video-1-1080p.webm';
	const tabletVideoWebm = 'https://www.poderecollesanmassimo.com/assets/video/video-1-720p.webm';
	const mobileVideoWebm = 'https://www.poderecollesanmassimo.com/assets/video/video-1-480p.webm';

	const getVideoSrc = (width, type) => {
		if (type === 'mp4') {
			if (width >= 1080) return desktopVideoMp4; //1080p version for desktop
			if (width >= 720) return tabletVideoMp4; //720p version for tablets
			return mobileVideoMp4; //480p version for mobile devices
		}
		if (type === 'webm') {
			if (width >= 1080) return desktopVideoWebm; //1080p version for desktop
			if (width >= 720) return tabletVideoWebm; //720p version for tablets
			return mobileVideoWebm; //480p version for mobile devices
		}
	};
	const srcWebm = getVideoSrc(window.innerWidth, 'webm');
	const srcMp4 = getVideoSrc(window.innerWidth, 'mp4');

	const onLoadedData = () => {
		setIsVideoLoaded(true);
	};

	// All the ref to be observed
	const intersectionVideo = useIntersection(videoRef, {
		root: null,
		rootMargin: '0px',
		threshold: 1
	});
	console.log(intersectionVideo);

	return (
		<Page {...props}>
			<section className="production-features">
				<div className="video-header">
					<img
						src={videoThumb}
						className="video-thumb"
						alt="thumb"
						style={{ opacity: isVideoLoaded ? 0 : 1, display: isVideoLoaded ? 'none' : 'block' }}
					/>
					<video
						autoPlay
						playsInline
						muted
						poster={videoThumb}
						onLoadedData={onLoadedData}
						style={{ opacity: isVideoLoaded ? 1 : 0 }}
						ref={videoRef}
						onEnded={() => {
							intersectionVideo &&
								intersectionVideo.intersectionRatio < 1 &&
								scrollTo(contentRef.current);
						}}
					>
						<source src={srcWebm} type="video/webm;codecs=&quot;vp8, vorbis&quot;" />
						<source src={srcMp4} type="video/mp4;codecs=&quot;avc1.42E01E, mp4a.40.2&quot;" />
					</video>

					{/* <div class="viewport-header">
						<h1>
							<span>non solo</span>
							Vino
						</h1>
					</div> */}
				</div>
				<div className="production-content" ref={contentRef}>
					<div className="container-fluid reverse">
						<div className="text">
							<h2>
								<FormattedMessage id="production.title-area" />
							</h2>
							<p className="text-justify">
								<FormattedMessage id="production.content-area" />
							</p>
						</div>
						<div className="image">
							<img src={ProductionImg1} alt="Vini - Wines" />
						</div>
					</div>
					<div className="container-fluid blk-bkg">
						<div className="text">
							<h1>
								<FormattedMessage id="production.title-wine" />
							</h1>
							<p className="text-justify">
								<FormattedMessage id="production.content-wine" />
							</p>
							<br />
							<br />

							<h2>
								<FormattedMessage id="production.prizes-title" />
							</h2>
							<div className="prize">
								<a
									href="https://wow.civiltadelbere.com/edizione-2020/medaglie-doro-2020-2/"
									title="WOW International Prize 2020 - Terra Bruna, Colline Teramane Montepulciano d’Abruzzo Docg 2015"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={LogoWow} alt="Wow Edizione 2020" />
								</a>
								<p>
									<FormattedMessage id="production.prizes-wow-2020" />
									<br />
									<a
										href="https://www.poderecollesanmassimo.com/assets/pdf/PodereColleSanMassimo-Wow2020.pdf"
										title="WOW International Prize 2020 - Terra Bruna, Colline Teramane Montepulciano d’Abruzzo Docg 2015"
										target="_blank"
										rel="noopener noreferrer"
									>
										<FormattedMessage id="production.see-prize" />
									</a>
								</p>
							</div>
							<p>
								<FormattedMessage id="production.prizes-wif-2020" />
								<br />
								<FormattedMessage id="production.prizes-wif-2020-1" />
							</p>
							<h2>
								<FormattedMessage id="production.mentions-title" />
							</h2>
							<br />

							<p>
								<strong>
									<FormattedMessage id="production.prizes-bibenda-2020" />
								</strong>
							</p>
							<p>
								<strong>
									<FormattedMessage id="production.prizes-bibenda-2020-1" />
								</strong>
								<br />
								<small>
									<FormattedMessage id="production.prizes-bibenda-2020-1-points" />
								</small>
								<br />
								<strong>
									<FormattedMessage id="production.prizes-bibenda-2020-2" />
								</strong>
								<br />
								<small>
									<FormattedMessage id="production.prizes-bibenda-2020-2-points" />
								</small>
								<br />
								<strong>
									<FormattedMessage id="production.prizes-bibenda-2020-3" />
								</strong>
								<br />
								<small>
									<FormattedMessage id="production.prizes-bibenda-2020-3-points" />
								</small>
							</p>

							<br />

							<p>
								<strong>
									<FormattedMessage id="production.prizes-guida-2020" />
								</strong>
							</p>
							<p>
								<strong>
									<FormattedMessage id="production.prizes-guida-2020-1" />
								</strong>
								<br />
								<small>
									<FormattedMessage id="production.prizes-guida-2020-1-points" />
								</small>
								<br />
								<strong>
									<FormattedMessage id="production.prizes-guida-2020-2" />
								</strong>
								<br />
								<small>
									<FormattedMessage id="production.prizes-guida-2020-2-points" />
								</small>
							</p>
						</div>
						<div className="image">
							<img src={ProductionImg3} alt="Vini - Wines" />
						</div>
					</div>
					<div className="container-fluid">
						<div className="image">
							<img src={ProductionImg5} alt="Vini - Wines" />
						</div>
						<div className="text">
							<h2>
								<FormattedMessage id="production.title-oil" />
							</h2>
							<p className="text-justify">
								<FormattedMessage id="production.content-oil" />
							</p>
						</div>
					</div>
					<div className="parallax-section">
						<div className="parallax-child-section">
							<section
								className="fw-main-row frontlash bg-cover"
								style={{ backgroundImage: `url(${ParallaxImg})` }}
							/>
						</div>
					</div>
				</div>
			</section>
		</Page>
	);
};

export default Production;
