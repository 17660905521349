import React, { useState, useEffect } from 'react';
import { withGoogleMap, withScriptjs, GoogleMap, Marker, InfoWindow } from 'react-google-maps';
import * as distributorsData from '../data/distributors-data.json';
import mapStyles from '../util/mapStyles';
//starting in Ottawa, coords taken from https://www.latlong.net/
//npmjs.com/package/@react-google-maps/api
const Map = (props) => {
	const [ selectedMarker, setSelectedMarker ] = useState(null);
	// const [ setMap ] = useState(null);
	//let map = useRef(null);
	useEffect(() => {
		const listener = (e) => {
			if (e.key === 'Escape') {
				setSelectedMarker(null);
			}
		};
		window.addEventListener('keydown', listener);

		return () => {
			window.removeEventListener('keydown', listener);
		};
	}, []);

	//AUTO SET TO CENTER THE MAP (also add to <GoogleMap> ref={(map) => map && map.fitBounds(bounds)})
	//from: https://github.com/tomchentw/react-google-maps/issues/305
	/* const bounds = new window.google.maps.LatLngBounds();
	distributorsData.features.map((model) => {
		const longitude = model.geometry.coordinates[0];
		const latitude = model.geometry.coordinates[1];
		const latLng = new window.google.maps.LatLng(latitude, longitude);
		console.log('latLng', latLng);
		bounds.extend(latLng);
		return latLng;
	}); */
	const bounds = new window.google.maps.LatLngBounds();
	distributorsData.features.map((model) => {
		const longitude = model.geometry.coordinates[0];
		const latitude = model.geometry.coordinates[1];
		const latLng = new window.google.maps.LatLng(latitude, longitude);
		bounds.extend(latLng);
		return latLng;
	});

	// map.fitBounds(bounds); //# auto-zoom
	// map.panToBounds(bounds); //# auto-center

	/* 
	// the smooth zoom function - DOES NOT WORK RIGHT NOW
	const smoothZoom = (map, max, cnt) => {
		if (cnt >= max) {
			return;
		} else {
			let y = window.google.maps.event.addListener(map, 'zoom_changed', function(event) {
				window.google.maps.event.removeListener(y);
				smoothZoom(map, max, cnt + 1);
			});
			setTimeout(function() {
				map.setZoom(cnt);
			}, 80);
		}
	};
	*/

	return (
		<GoogleMap
			ref={(map) => map && map.fitBounds(bounds)}
			defaultZoom={8}
			//defaultCenter={{ lat: 45.4211, lng: -75.6903 }}
			defaultCenter={{ lat: 46.01714, lng: 8.90867 }}
			defaultOptions={{ styles: mapStyles }}
			// onLoad={onLoad}
			// onUnmount={onUnmount}
		>
			{distributorsData.features.map((distributor) => (
				<Marker
					key={distributor.properties.ID}
					position={{
						lat: distributor.geometry.coordinates[1],
						lng: distributor.geometry.coordinates[0]
					}}
					onClick={() => {
						//pass map to arrow function (map) => {
						//console.log(map.getZoom());
						//map && smoothZoom(map, 12, map.getZoom());

						setSelectedMarker(distributor);
					}}
					icon={{
						url: `/logo-marker.svg`,
						scaledSize: new window.google.maps.Size(55, 55)
					}}
				/>
			))}

			{selectedMarker && (
				<InfoWindow
					onCloseClick={() => {
						setSelectedMarker(null);
					}}
					position={{
						lat: selectedMarker.geometry.coordinates[1],
						lng: selectedMarker.geometry.coordinates[0]
					}}
				>
					<div>
						<h2>{selectedMarker.properties.NAME}</h2>
						<p>{selectedMarker.properties.TITLE}</p>
						<p>
							{selectedMarker.properties.ADDRESS}
							<br /> {selectedMarker.properties.ADDRESS1}
						</p>
					</div>
				</InfoWindow>
			)}
		</GoogleMap>
	);
};

const GMaps = (props) => {
	return (
		<div style={{ width: `${props.vwWidth}vw`, height: `${props.vhHeight}vh` }}>
			<MapWrapped
				isMarkerShown={false}
				googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBwF_TCcbmkctdkMfYgdAQKghv-kemq25A`}
				loadingElement={<div style={{ height: `100%` }} />}
				containerElement={<div style={{ height: `100%` }} />}
				mapElement={<div style={{ height: `100%` }} />}
				data={props.data}
			/>
		</div>
	);
};
//can put at the end of googleMapUrl as &key=yourKey
//or create .env.local and copy it: REACT_APP_GOOGLE_KEY = "AIzaSyAhoAoSQLSRs8ljQYGdXv6Uapaf2mX2iso";

const MapWrapped = withScriptjs(withGoogleMap(Map));

export default GMaps;
