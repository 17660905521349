import React, { useRef, useEffect } from 'react';
import gsap from 'gsap';
//import { Link, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import { Link } from 'react-scroll';
import { FormattedMessage } from 'react-intl';

//IMAGES
//import Logo from '../images/logo_transparent.png';
import { ReactComponent as Logo } from '../assets/logo-clear.svg';
import Background from '../images/header-img.jpg';

export default function HomeHead() {
	const sectionStyle = {
		width: '100%',
		height: '100vh',
		backgroundImage: `url(${Background})`
	};

	let logo = useRef(null);
	let sitename = useRef(null);
	// let mainhead = useRef(null);
	let scrollIcon = useRef(null);
	let scrollText = useRef(null);

	useEffect(() => {
		gsap.from(logo, 1, {
			delay: 1.5,
			opacity: 0,
			y: 20,
			ease: 'expo.easeInOut'
		});

		gsap.from(sitename, 1, {
			delay: 2,
			opacity: 0,
			y: 20,
			ease: 'expo.easeInOut'
		});

		/* gsap.from(mainhead, 1.2, {
			delay: 1.5,
			opacity: 0,
			y: 10,
			ease: 'expo.easeInOut'
		}); */

		gsap.from(scrollIcon, 1, {
			delay: 2.1,
			opacity: 0,
			y: 20,
			ease: 'expo.easeInOut'
		});

		gsap.from(scrollText, 1, {
			delay: 2.4,
			opacity: 0,
			y: 20,
			ease: 'expo.easeInOut'
		});
	}, []);

	return (
		<div style={sectionStyle} className="homehead" id="home">
			<div className="wrapper">
				<div className="head">
					<div className="logo">
						<Logo
							ref={(el) => {
								logo = el;
							}}
							alt="Podere Colle San Massimo - Azienda Vitivinicola - Winery"
							className="logo"
						/>
					</div>
					<h1
						ref={(el) => {
							sitename = el;
						}}
					>
						{/* <FormattedMessage id="home.title" /> */}
						<span>
							<FormattedMessage id="home.subtitle" />
						</span>
					</h1>
				</div>
				{/* <div
					className="mainhead"
					ref={(el) => {
						mainhead = el;
					}}
				>
					<i>
						<FormattedMessage id="home.claim" />
					</i>
				</div> */}
				<div className="scroll-animation">
					<Link to="home-features" spy={true} smooth={true} duration={500}>
						<div
							className="icon-scroll"
							ref={(el) => {
								scrollIcon = el;
							}}
						/>
					</Link>
					<span
						ref={(el) => {
							scrollText = el;
						}}
					>
						{/* <Link activeClass="active" to="features" spy={true} smooth={true} duration={500}> */}
						<FormattedMessage id="home.enter" />
						{/* </Link> */}
					</span>
				</div>
			</div>
		</div>
	);
}
