import React from 'react';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Facebook, Whatsapp, Youtube, Instagram } from './SvgIcons';
/* import WhatsAppWidget from 'react-whatsapp-chat-widget';
import 'react-whatsapp-chat-widget/index.css'; */

//SVG
import { ReactComponent as LogoFooter } from '../assets/logo-footer.svg';
const Footer = () => {
	return (
		<>
			<footer>
				<div>
					<LogoFooter className="logo" />
				</div>

				<div className="footer-wrapper">
					<div className="footer-side">
						<h3>Podere Colle San Massimo Soc. Agricola</h3>
						<p className="company-data">
							Via Colle San Massimo, 16 <br />
							64021 Giulianova (TE) - Italy <br />
							P.IVA / VAT: 01814920672 - SDI QULXG4S
						</p>
						<br />
						<a href="mailto:info@poderecollesanmassimo.com " title="Send an Email to Podere Colle San Massimo">
							info@poderecollesanmassimo.com
						</a>
					</div>
					<div className="footer-side">
						<p className="company-data">
							<p>e-Mail:</p>
							<a
								href="mailto:info@poderecollesanmassimo.com "
								title="Send an Email to Podere Colle San Massimo"
							>
								info@poderecollesanmassimo.com
							</a>
							<br />
							<br />
							<p>Tel/Phone:</p>

							<a href="tel:00393355650784 " title="Call Podere Colle San Massimo">
								{/* <span className="icon">
									<PhoneIcon />
								</span> */}
								+39 335 565 0784
							</a>
							<br />
							<a href="tel:00393398512963 " title="Call Podere Colle San Massimo">
								{/* <span className="icon">
									<PhoneIcon />
								</span> */}
								+39 339 851 2963
							</a>
						</p>
						<div className="social-icons">
							<a
								href="https://www.facebook.com/poderecollesanmassimo"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Facebook />
							</a>

							<a
								href="https://www.youtube.com/channel/UCByeU18wTrIwMwn1eaNjNCA"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Youtube />
							</a>
							<a
								href="https://www.instagram.com/podere_colle_san_massimo/?utm_medium=copy_link"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Instagram />
							</a>
							<a
								href="https://api.whatsapp.com/send?phone=393355650784"
								target="_blank"
								rel="noopener noreferrer"
							>
								<Whatsapp />
							</a>
						</div>
					</div>
				</div>

				<div className="bottom">2020, Podere Colle San Massimo</div>
			</footer>
			{/* <WhatsAppWidget
				phoneNo="919884098840"
				position="right"
				widgetWidth="300px"
				widgetWidthMobile="260px"
				autoOpen={true}
				autoOpenTimer={5000}
				messageBox={true}
				messageBoxTxt="Hi Team, is there any related service available ?"
				iconSize="40"
				iconColor="white"
				iconBgColor="tomato"
				headerIcon="https://proficientdesigners.in/wp-content/themes/pd/img/logo-new.png"
				headerIconColor="pink"
				headerTxtColor="black"
				headerBgColor="tomato"
				headerTitle="John Doe"
				headerCaption="Online"
				bodyBgColor="#bbb"
				chatPersonName="Support"
				chatMessage={<>Hi there 👋 <br /><br /> How can I help you?</>}
				footerBgColor="#999"
				btnBgColor="yellow"
				btnTxtColor="black"
				btnTxt="Start Chat"
			/> */}
		</>
	);
};

export default Footer;
